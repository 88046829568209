import { DetailedHTMLProps, forwardRef, ReactElement, TextareaHTMLAttributes } from 'react';

import thematize from 'lib/thematize';
import Error from 'components/Base/Input/Error';
import styles from './Textarea.scss';

const theme = thematize(styles);

type Props = DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement> & {
  className?: string;
  error?: string;
  errorClassName?: string;
  invalid?: boolean;
  value?: string;
};

const Textarea = forwardRef<HTMLTextAreaElement, Props>(
  (
    { invalid = false, error = '', className = '', errorClassName = '', value, ...rest },
    ref
  ): ReactElement => (
    <div className={theme('container')}>
      <textarea
        ref={ref}
        className={`${theme('textarea', {
          invalid: !!(invalid || error),
        })} ${className}`}
        value={value}
        {...rest}
      />
      {error && <Error className={errorClassName} error={error} />}
    </div>
  )
);

export default Textarea;
