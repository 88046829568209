import { ComponentProps, forwardRef, Ref } from 'react';
import { Virtuoso, VirtuosoHandle } from 'react-virtuoso';

const VirtualizedList = forwardRef(
  (
    { useWindowScroll = true, ...props }: ComponentProps<typeof Virtuoso>,
    ref: Ref<VirtuosoHandle> | undefined
  ) => <Virtuoso {...props} useWindowScroll={useWindowScroll} ref={ref} />
);

export default VirtualizedList;
