import { CSSProperties, FC } from 'react';

import thematize from 'lib/thematize';
import styles from './Skeleton.scss';

const theme = thematize(styles);

export const Skeleton: FC<CSSProperties> = ({ ...styles }) => (
  <div aria-labelledby="skeleton" className={theme('skeleton')} style={styles} />
);
